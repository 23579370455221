import Link from 'next/link'

export default function cta({ children, href = null, className, classNameButton = '', onClick = null, type = 'lg' }) {
  const style = {
    lg: 'px-8 py-3 text-lg font-normal tracking-wider border-2 border-orange md:text-2xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange',
    sm: 'px-4 py-1.5 text-base font-normal tracking-wider border-2 border-orange md:text-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange'
  }

  if (onClick && !href) {
    return (
      <div className={`flex justify-center ${className}`}>
        <button className={`${style[type]} ${classNameButton} font-serif text-orange `} onClick={onClick}>
          {children}
        </button>
      </div>
    )
  }

  return (
    <div className={`flex justify-center ${className}`}>
      <Link href={href}>
        <a className={`${style[type]} ${classNameButton} font-serif text-orange `}>{children}</a>
      </Link>
    </div>
  )
}
