export const main = [
  {
    name: 'Services',
    href: '/services'
  },
  {
    name: 'Portfolio',
    href: '/portfolio'
  },
  {
    name: 'Équipe',
    href: '/equipe'
  },
  {
    name: 'Blog',
    href: '/blog'
  },
  {
    name: 'Shop',
    href: '/shop'
  },
  {
    name: 'Nous contacter',
    href: '/contact'
  }
]

export const footer = [
  {
    name: 'Accueil',
    bold: true,
    href: '/'
  },
  {
    name: 'Services',
    bold: false,
    href: '/services'
  },
  {
    name: 'Portfolio',
    bold: false,
    href: '/portfolio'
  },
  {
    name: 'Equipe',
    bold: false,
    href: '/equipe'
  },
  {
    name: 'Blog',
    bold: false,
    href: '/blog'
  },
  {
    name: 'Shop',
    bold: false,
    href: '/shop'
  },
  {
    name: 'CGV',
    bold: false,
    href: '/cgv'
  },
  {
    name: 'Livraison & retour',
    bold: false,
    href: '/livraison-retour'
  },
  {
    name: 'SAV',
    bold: false,
    href: '/sav'
  },
  {
    name: 'FAQ Shop',
    bold: false,
    href: '/faq'
  },
  {
    name: 'Contact',
    bold: false,
    href: '/contact'
  }
]
