import { Fragment, useContext } from 'react'
import Layout from 'components/layout'
import Link from 'next/link'
import { ShowNewsLetterContext } from 'context/popup'

import Cta from 'components/cta'
import Facebook from 'components/social/facebook'
import Instagram from 'components/social/instagram'
import Pinterest from 'components/social/pinterest'

import { footer as footerMenuData } from 'data/menu'
import socialInfo from 'data/social'

export default function footer() {
  const [showPopup, setShowPopup] = useContext(ShowNewsLetterContext)

  return (
    <footer className="bg-yellow-50 pb-12 pt-16">
      <Layout>
        <div className="grid gap-4 md:grid-cols-4">
          <div className="md:col-span-2">
            <div className="grid grid-flow-col grid-cols-3">
              {[0, 1, 2].map((thisColumn) => {
                const numberInCol = Math.ceil((footerMenuData.length + 1) / 3)
                const startIndex = thisColumn * numberInCol
                const menuData = footerMenuData.slice(startIndex, startIndex + 3)
                return (
                  <div key={thisColumn}>
                    {menuData.map((thisLink, index, array) => (
                      <div key={thisLink.href + thisLink.name}>
                        <Link href={thisLink.href}>
                          <a className="uppercase">{thisLink.name}</a>
                        </Link>
                      </div>
                    ))}

                    {thisColumn === 2 && (
                      <div className="md:row-span-2">
                        <button onClick={() => setShowPopup(true)} className="text-left uppercase text-orange">
                          S'abonner à la newsletter
                        </button>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div>
            <div className="flex h-full items-end justify-center space-x-2 py-4 md:py-0 md:pb-1">
              {socialInfo.facebookURL && (
                <a
                  href={socialInfo.facebookURL}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-orange-200 text-white focus:outline-none focus:ring-2 focus:ring-orange focus:ring-offset-2">
                  <Facebook className="h-4 w-4" />
                </a>
              )}
              {socialInfo.instagramURL && (
                <a
                  href={socialInfo.instagramURL}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-orange-200 text-white focus:outline-none focus:ring-2 focus:ring-orange focus:ring-offset-2">
                  <Instagram className="h-4 w-4" />
                </a>
              )}
              {socialInfo.pinterestURL && (
                <a
                  href={socialInfo.pinterestURL}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-orange-200 text-white focus:outline-none focus:ring-2 focus:ring-orange focus:ring-offset-2">
                  <Pinterest className="h-4 w-4" />
                </a>
              )}
            </div>
          </div>
          <div className="flex items-end justify-end text-right">
            <div>
              <p>
                <span className="font-bold">&copy; {new Date().getFullYear()} CM-ECREATE.</span>
              </p>
              <p className="uppercase">Tous droits réservés.</p>
            </div>
          </div>
        </div>
        <button onClick={() => window?.openAxeptioCookies()} className="mt-8 font-light text-gray-700 underline">
          Cliquez-ici pour modifier vos préférences en matière de cookies
        </button>
      </Layout>
    </footer>
  )
}
