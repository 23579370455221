export default function Layout({ children, className = '', isHeader = false }) {
  return <div className={`${className} lg:px-10 mx-auto px-6 max-w-5xl sm:px-8`}>{children}</div>
}

export function LargeLayout({ children, className = '', isHeader = false }) {
  return <div className={`${className} lg:px-10 mx-auto px-6 max-w-7xl sm:px-8`}>{children}</div>
}

export function Textlayout({ children }) {
  return <div className="px-0 md:px-28">{children}</div>
}
